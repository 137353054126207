<template>
	<v-layout column fill-height>
		<v-flex shrink text-xs-right>
			<w-btn icon="add" @click="addFakeCatalogTheme()">{{ $t('sa.catalog_themes.actions.create') }}</w-btn>
		</v-flex>
		<v-flex scroll-y>
			<CatalogThemesList 
				:items="catalogThemes" 
				:loading="loading"
				:service="service"
			/>
		</v-flex>
	</v-layout>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'

export default {
	name: 'CatalogThemesManager',
	components: {
		CatalogThemesList: () => ({
			component: import('@/components/SuperAdmin/CatalogThemes/CatalogThemesList')
		})
	},
	mixins: [SuperAdminModuleGuard],
	data: function () {
		return {
			catalogThemes: [],
			loading: false,
			permissions: []
		}
	},
	mounted: function () {
		this.loadCatalogThemes()
		this.loadPermissions()
	},
	methods: {
		addFakeCatalogTheme: function () {
			this.catalogThemes.push({
				color: this.$vuetify.theme.primary,
				is_for_accounting_firms: true,
				is_for_companies: true,
				title: '',
				theme_permission_id: this.permissions[0]?.value
			})
		},
		loadCatalogThemes: function () {
			this.loading = true
			this.service.listCatalogThemes({ 'additional-fields': ['is_instanciated'] })
				.then(catalogThemes => {
					this.catalogThemes = catalogThemes
				})
				.finally(() => {
					this.loading = false
				})
		},
		loadPermissions: function () {
			this.service.getACLList()
				.then(permissions => {
					this.permissions = permissions.map(acl => ({
						text: this.$t(acl.title),
						value: acl.id
					}))
				})
		},
		saveCatalogTheme: function (theme) {
			if (theme.id) {
				this.service.updateCatalogTheme(theme.id, theme).then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('settings.messages.theme_updated'))
				})
			} else {
				this.service.createCatalogTheme(theme).then(res => {
					this.catalogThemes = this.catalogThemes.map(item => {
						return item.title === res.title ? res : item
					})
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('settings.messages.theme_created'))
				})
			}
		}
	}
}
</script>
